import {defineStore} from 'pinia'

interface UseBasicLayoutState {
    head: boolean
    footer: boolean
    breadcrumb: string
}



export const useBasicLayoutStore = defineStore('basicLayout', {
    state: (): UseBasicLayoutState => ({
        head: true,
        footer: true,
        breadcrumb: ''
    }),
    getters: {
        getHead: (state) => {
            return state.head
        },
        getFooter: (state) => {
            return state.footer
        },
        getBreadcrumb: (state) => {
            return state.breadcrumb
        }
    },
    actions: {
        setHead(head: boolean): void {
            this.head = head
        },
        setFooter(footer: boolean): void {
            this.footer = footer
        },
        setBreadcrumb(breadcrumb: string): void {
            this.breadcrumb = breadcrumb
        }
    },
    persist: {
        storage: persistedState.sessionStorage
    }
})

